import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 224.000000 225.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd">
          <path d="M1025 2190 c-265 -41 -454 -115 -609 -240 -320 -256 -457 -655 -365
-1063 18 -78 19 -49 2 58 -10 68 -13 131 -9 195 11 165 21 156 21 -18 1 -142
4 -181 24 -257 28 -106 91 -247 152 -336 54 -80 177 -204 249 -252 30 -21 50
-37 44 -37 -26 0 -158 106 -238 192 -72 75 -81 83 -51 40 55 -77 178 -195 259
-249 90 -60 224 -118 335 -145 117 -30 349 -32 466 -4 103 24 234 76 310 124
256 160 390 292 485 482 126 249 151 502 75 765 -104 359 -392 638 -750 726
-93 23 -308 33 -400 19z m249 -155 c130 -28 258 -87 365 -170 158 -122 299
-322 332 -472 7 -29 15 -53 20 -53 15 0 40 -180 34 -238 -25 -212 -142 -454
-281 -582 -36 -34 -36 -33 8 20 55 67 119 161 145 215 44 87 94 270 92 333 0
15 -7 -1 -14 -36 -61 -280 -255 -519 -520 -642 -216 -100 -466 -113 -684 -36
-87 31 -243 159 -326 267 -116 149 -158 280 -158 489 0 164 21 256 92 405 111
233 302 406 541 491 53 19 86 23 175 23 61 1 141 -6 179 -14z m-554 -59 c0 -2
-21 -17 -47 -32 -116 -67 -235 -177 -304 -281 -49 -73 -50 -60 -3 26 57 105
150 188 289 260 64 33 65 33 65 27z m1241 -1336 c-23 -38 -53 -82 -67 -97 -22
-25 -21 -22 6 21 16 28 30 54 30 59 0 13 63 98 68 92 3 -2 -14 -36 -37 -75z
m-206 -198 c-107 -102 -231 -173 -380 -218 -80 -24 -106 -28 -235 -28 -141 0
-148 0 -233 33 -96 36 -109 51 -25 30 32 -9 107 -14 193 -14 125 1 153 4 262
33 197 51 307 101 422 188 65 50 64 41 -4 -24z"/>
          <path d="M595 1358 c-86 -31 -154 -121 -162 -218 -13 -140 84 -254 227 -267
150 -14 259 76 260 215 l0 52 -115 0 -115 0 0 -45 0 -45 70 0 c39 0 70 -4 70
-8 0 -20 -65 -72 -97 -78 -78 -15 -142 9 -187 70 -28 37 -28 128 -1 174 23 39
87 72 139 72 39 0 111 -31 118 -51 5 -14 34 -5 67 20 l23 18 -38 36 c-64 60
-177 84 -259 55z"/>
          <path d="M1550 1352 c-47 -23 -80 -75 -80 -124 0 -75 40 -111 168 -153 59 -19
82 -37 82 -65 0 -59 -122 -66 -172 -11 l-27 31 -36 -21 c-19 -12 -35 -25 -35
-30 0 -5 16 -27 36 -49 43 -48 95 -64 177 -57 99 9 151 64 144 150 -6 66 -39
99 -136 133 -110 39 -135 69 -93 107 33 30 100 17 130 -24 12 -17 14 -17 47 2
19 12 35 25 35 29 0 17 -51 68 -84 84 -44 21 -112 20 -156 -2z"/>
          <path d="M1000 1121 l0 -241 120 0 c131 0 163 8 218 55 54 45 76 99 76 186 1
67 -3 81 -29 124 -17 26 -50 62 -73 79 -43 30 -46 31 -178 34 l-134 4 0 -241z
m253 137 c32 -17 46 -33 62 -72 21 -49 19 -100 -5 -146 -27 -53 -63 -70 -150
-70 l-70 0 0 150 0 150 71 0 c39 0 80 -5 92 -12z"/>
          <path d="M1992 1150 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
